
#amounts[data-v-1eb0efbf] {
  height: 200px;
  font-size: 12px;
}
#mops[data-v-1eb0efbf] {
  height: 100px;
  font-size: 12px;
}

